import {useCallback} from 'react';

function usePageScrollLock() {
    const lockScroll = useCallback(() => {
      document.body.classList.add("scroll-lock");
    }, [])
  
    const unlockScroll = useCallback(() => {
      document.body.classList.remove("scroll-lock");
    }, []);
  
    return {
      lockScroll,
      unlockScroll
    };
}

export default usePageScrollLock;