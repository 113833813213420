import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { EditOutlined } from '@material-ui/icons';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';

import useOnClickOutside from '../../Hooks/useOnClickOutside';
import { IS_DESKTOP_MEDIA_QUERY } from '../../Misc/constants';
import { Images } from '../../static/images';

import './index.scss';
import './burgermenu.scss';

const Header = () => {
    const checkboxRef = useRef();
    const menuRef = useRef();
    const [t] = useTranslation();
    const isDesktop = useMediaQuery(IS_DESKTOP_MEDIA_QUERY);
    const setCheckboxValue = (value) => {
        checkboxRef.current.checked = value;
    };

    useOnClickOutside(menuRef, () => setCheckboxValue(false));

    const closeMenu = () => {
        checkboxRef.current.checked = false;
    };

    const handleLogout = () => {
        checkboxRef.current.checked = false;
        localStorage.removeItem('token');
        window.location.href = '/';
    };

    return (
        <div className="header">
            <div className="header-desktop-wrapper">
                <div>
                    <NavLink to="/" exact>
                        <img src={isDesktop ? Images.LogoMiddle : Images.LogoSmall} alt="GPG App Logo" height={50} />
                    </NavLink>
                </div>
                <div className="nav-wrapper">
                    <NavLink to="/" exact>
                        <div className="nav-item fs-14 additional_grey_6 uppercase">{t('gigs')}</div>
                    </NavLink>
                    <NavLink to="/restaurants" exact>
                        <div className="nav-item fs-14 additional_grey_6 uppercase">{t('restaurants')}</div>
                    </NavLink>
                    <NavLink to="/history" exact>
                        <div className="nav-item fs-14 additional_grey_6 uppercase">{t('archive')}</div>
                    </NavLink>
                    <NavLink to="/managers" exact>
                        <div className="nav-item fs-14 additional_grey_6 uppercase">{t('managers')}</div>
                    </NavLink>
                    <NavLink to="/gigslers" exact>
                        <div className="nav-item fs-14 additional_grey_6 uppercase">Gigslers</div>
                    </NavLink>
                </div>
                <div className="menu-wrapper">
                    <div id="menuToggle" ref={menuRef}>
                        <input type="checkbox" ref={checkboxRef} />
                        <div id="burger">
                            <span />
                            <span />
                            <span />
                        </div>
                        <div id="menu">
                            <NavLink to="/profile" className="menu-item" onClick={closeMenu}>
                                <AccountCircleOutlinedIcon color="primary" />
                                <span>&nbsp;{t('user_info')}</span>
                            </NavLink>
                            {/*<NavLink to="/profile#1" className="menu-item" onClick={closeMenu}>*/}
                            {/*    <PaymentOutlinedIcon color="primary"/>*/}
                            {/*    <span>&nbsp;Payment Options</span>*/}
                            {/*</NavLink>*/}
                            <NavLink to="/report" className="menu-item" onClick={closeMenu}>
                                <AssignmentOutlinedIcon color="primary" />
                                <span>&nbsp;{t('report')}</span>
                            </NavLink>
                            <NavLink to="/tax-report" className="menu-item" onClick={closeMenu}>
                                <AssignmentOutlinedIcon color="primary" />
                                <span>&nbsp;{t('tax_report')}</span>
                            </NavLink>
                            <NavLink to="/gig-types" className="menu-item" onClick={closeMenu}>
                                <EditOutlined color="primary" />
                                <span>&nbsp;{t('edit_gig_types')}</span>
                            </NavLink>
                            <div className="menu-item" onClick={handleLogout}>
                                <ExitToAppOutlinedIcon color="error" />
                                <span>&nbsp;{t('sign_out')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="nav-wrapper-mob">
                <NavLink to="/" exact>
                    <div className="nav-item fs-14 additional_grey_6 uppercase">{t('gigs')}</div>
                </NavLink>
                <NavLink to="/restaurants" exact>
                    <div className="nav-item fs-14 additional_grey_6 uppercase">{t('restaurants')}</div>
                </NavLink>
                <NavLink to="/history" exact>
                    <div className="nav-item fs-14 additional_grey_6 uppercase">{t('archive')}</div>
                </NavLink>
                <NavLink to="/managers" exact>
                    <div className="nav-item fs-14 additional_grey_6 uppercase">Managers</div>
                </NavLink>
                <NavLink to="/moonlighters" exact>
                    <div className="nav-item fs-14 additional_grey_6 uppercase">Moonlighters</div>
                </NavLink>
            </div>
        </div>
    );
};

export default Header;
