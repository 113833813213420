import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { BlockOutlined, CompareArrows } from '@material-ui/icons';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Rating from '@material-ui/lab/Rating';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment/moment';

import OverlayLoader from '../../../Components/OverlayLoader/OverlayLoader';
import { AppContext } from '../../../Contexts/AppContext';
import { EmployerContext } from '../../../Contexts/EmployerContext';
import { getEmployeeBindings, getMoonlighters, Send } from '../../../Misc/backend';

import './MoonlightersPage.scss';

const MoonlightersPage = () => {
    const [loading, setLoading] = useState(false);
    const [moonlightersList, setMoonlightersList] = useState([]);
    const [t] = useTranslation();
    const { appState } = useContext(AppContext);
    const { employerState } = useContext(EmployerContext);
    const [searchItem, setSearchItem] = useState('');
    const [filteredMoonlightersList, setFilteredMoonlightersList] = useState([]);
    const [ratingFilter, setRatingFilter] = useState(0);
    const [skillsFilter, setSkillsFilter] = useState('_none');
    const [internalIdRestaurantNamesMap, setInternalIdRestaurantNamesMap] = useState({});
    const restIds = useMemo(() => employerState.restaurants.map((item) => item.id), [employerState.restaurants]);
    const restNames = useMemo(
        () =>
            employerState.restaurants.reduce((accumulator, currentValue) => {
                accumulator[currentValue.id] = currentValue.name;
                return accumulator;
            }, {}),
        [employerState.restaurants]
    );

    useEffect(() => {
        async function loadMoonlighters() {
            setLoading(true);
            try {
                const moonlighters = await getMoonlighters();
                setMoonlightersList(moonlighters);
                setFilteredMoonlightersList(moonlighters);
                const restaurantsBindings = {};
                for await (const restId of restIds) {
                    restaurantsBindings[restId] = (await getEmployeeBindings(restId)).map((item) => item.internalId);
                }
                const map = {};
                moonlighters.forEach((moonlighter) => {
                    const restaurantsOfCurrentMoonlighter = [];
                    for (let restId in restaurantsBindings) {
                        if (restaurantsBindings[restId].includes(moonlighter.id)) {
                            restaurantsOfCurrentMoonlighter.push(restId);
                        }
                    }
                    map[moonlighter.id] = restaurantsOfCurrentMoonlighter.map((item) => restNames[item]);
                });
                setInternalIdRestaurantNamesMap(map);
            } catch (e) {
                console.log(e);
            }
            setLoading(false);
        }

        loadMoonlighters();
    }, []);

    const handleBlockMoonlighter = async (id) => {
        if (confirm('Do you really want to block this Moonlighter?') == true) {
            try {
                await blockMoonlighter(id);
            } catch (e) {
                console.log(e);
            }
        }
    };

    useEffect(() => {
        const filteredItems = moonlightersList.filter(
            (item) =>
                (item.fullName.toLowerCase().includes(searchItem.toLowerCase()) ||
                    item.email.toLowerCase().includes(searchItem.toLowerCase())) &&
                item.rating >= ratingFilter &&
                (skillsFilter === '_none' || item.skillTagIds.includes(skillsFilter))
        );
        setFilteredMoonlightersList(filteredItems);
    }, [ratingFilter, searchItem, moonlightersList, skillsFilter]);

    const moonlightersGridColumns = [
        {
            field: 'userPicHash',
            type: 'custom',
            headerName: '',
            width: 60,
            resizable: false,
            hideable: false,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            display: 'flex',
            renderCell: (row) => (
                <div className="avatar">
                    {row.value ? <img src={appState.staticHost + '/' + row.value + '.jpg'} /> : <AccountCircleOutlinedIcon />}
                </div>
            ),
        },
        {
            field: 'fullName',
            type: 'string',
            headerName: `${t('name')}`,
            flex: 1,
            minWidth: 200,
            maxWidth: 400,
            disableColumnMenu: true,
            display: 'flex',
            align: 'left',
            renderCell: (row) => (
                <div className="name-wrapper">
                    <div className="fs-16 primary_black poppins bold">{row.value}</div>
                    <div className="binded">
                        {internalIdRestaurantNamesMap[row.id]?.length > 0 && (
                            <div className="additional_grey_2 fs-12">
                                <span className="">{internalIdRestaurantNamesMap[row.id]?.join(', ')}</span>
                            </div>
                        )}
                        {row.row.isSignedInExternalSystem && internalIdRestaurantNamesMap[row.id]?.length > 0 && (
                            <CompareArrows className="additional_grey_2" fontSize="small" />
                        )}
                        {row.row.isSignedInExternalSystem && <span className="additional_grey_2 fs-12">Personalkollen</span>}
                    </div>
                </div>
            ),
        },
        {
            field: 'email',
            type: 'string',
            headerName: `${t('email')}`,
            display: 'flex',
            flex: 1,
            minWidth: 200,
            maxWidth: 400,
            disableColumnMenu: true,
        },
        {
            field: 'rating',
            type: 'number',
            headerAlign: 'left',
            align: 'left',
            headerName: `${t('rating')}`,
            width: 200,
            disableColumnMenu: true,
            display: 'flex',
            renderCell: (row) => (
                <div className="rating">
                    <Rating value={row.value} readOnly></Rating>
                    <div>{row.value}/5</div>
                </div>
            ),
        },
        {
            field: 'lastSignedIn',
            type: 'string',
            headerName: `${t('last_signed_in')}`,
            width: 200,
            disableColumnMenu: true,
            display: 'flex',
            valueGetter: (value) => {
                if (!value) {
                    return '';
                }
                return moment(value).format('YYYY-MM-DD, h:mm');
            },
        },
        {
            field: 'skillTagIds',
            type: 'custom',
            headerName: `${t('skills')}`,
            width: 200,
            disableColumnMenu: true,
            sortable: false,
            display: 'text',
            valueGetter: (value) => {
                return appState.skills.filter((skill) => [...value].includes(skill.id)).map((skill) => skill.name);
            },
            renderCell: (row) => <div className="skills">{row.value.join(', ')}</div>,
        },
        {
            field: 'isActive',
            type: 'custom',
            headerName: '',
            flex: 0.5,
            headerAlign: 'right',
            filterable: false,
            disableColumnMenu: true,
            renderCell: (row) => (
                <div className="block-cell">
                    {!row.value && <span className="badge error">{t('blocked')}</span>}
                    {row?.value && (
                        <Button
                            variant="text"
                            color="secondary"
                            size="small"
                            title="Block Moonlighter"
                            onClick={() => handleBlockMoonlighter(row.id)}
                        >
                            <BlockOutlined color="error" />
                        </Button>
                    )}
                </div>
            ),
        },
    ];

    return (
        <OverlayLoader loading={loading}>
            <Helmet>
                <title>GPG App - Moonlighters list</title>
            </Helmet>
            <div className="page">
                <Box my={2} className="page-header poppins primary_black">
                    Gigslers
                </Box>
                <div className="card">
                    <div className="moonlighters-header">
                        <div className="search-input">
                            <TextField
                                id="moonlighters-search-field"
                                name="moonlighters-search-field"
                                variant="outlined"
                                color="secondary"
                                placeholder="Search gigslers..."
                                value={searchItem}
                                onChange={(e) => setSearchItem(e.target.value)}
                                fullWidth
                            />
                        </div>
                        <div className="filters">
                            <span className="fs-14 additional_grey_2">{t('show_by')}:</span>
                            <div className="flex-align-horizontal-middle select">
                                <span className="fs-14 additional_grey_2">{t('rating')}: </span>
                                <FormControl>
                                    <Select
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        id="layout-select"
                                        value={ratingFilter}
                                        onChange={(e) => setRatingFilter(e.target.value)}
                                    >
                                        <MenuItem value={0}>All</MenuItem>
                                        <MenuItem value={5}>
                                            <Rating value={5} readOnly size="small"></Rating>
                                        </MenuItem>
                                        <MenuItem value={4}>
                                            <Rating value={4} readOnly size="small"></Rating>
                                        </MenuItem>
                                        <MenuItem value={3}>
                                            <Rating value={3} readOnly size="small"></Rating>
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            <Rating value={2} readOnly size="small"></Rating>
                                        </MenuItem>
                                        <MenuItem value={1}>
                                            <Rating value={1} readOnly size="small"></Rating>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="flex-align-horizontal-middle select">
                                <span className="fs-14 additional_grey_2">{t('skills')}: </span>
                                <FormControl>
                                    <Select
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                        id="layout-select"
                                        value={skillsFilter}
                                        defaultValue="_none"
                                        onChange={(e) => setSkillsFilter(e.target.value)}
                                    >
                                        <MenuItem value="_none">None</MenuItem>
                                        {appState.skills.map(({ id, name }) => {
                                            return <MenuItem value={id}>{name}</MenuItem>;
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    {filteredMoonlightersList.length === 0 ? (
                        <div className="additional_grey_2 lh-46 fs-16">{t('no_gigslers_found')}</div>
                    ) : (
                        <Box className="grid-wrapper">
                            <DataGrid
                                loading={loading}
                                rows={filteredMoonlightersList}
                                columns={moonlightersGridColumns}
                                className="fs-16 poppins"
                                sx={{
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                    },
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                    '& .MuiDataGrid-columnHeader': {
                                        backgroundColor: '#F3F5F9',
                                        color: '#6C7684',
                                    },
                                    '& .MuiTablePagination-selectLabel': { display: 'none' },
                                    '& .MuiTablePagination-select': { display: 'none' },
                                    '& .MuiTablePagination-displayedRows': { margin: '0 auto' },
                                }}
                                initialState={{
                                    pagination: { paginationModel: { pageSize: 100 } },
                                }}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    )}
                </div>
            </div>
        </OverlayLoader>
    );
};

const blockMoonlighter = async (id) => {
    let method = 'post';
    let url = `/employers/me/moonlighters/${id}/blocks`;

    return await Send({
        method,
        url,
        data: {},
    });
};

const getBlockedMoonlighters = async (id) => {
    let method = 'get';
    let url = `/employers/me/moonlighter-blocks/${id}`;

    return await Send({
        method,
        url,
    });
};
export default MoonlightersPage;
